import { login, logout, refreshToken, getUserInfo, getUserLogo } from "../../services/api";
import { isAdminToken } from "../../helpers/tokenHelper";
import { refreshBeforeSend } from "../../services/interceptors";
import axios from "axios";

const state = {
  userObj: null,
  isAdmin: false,
  loggedIn: false,
  refreshingToken: false,
  logo: null
};

// getters
const getters = {
  getUserID: () => localStorage.getItem("userID"),
  getUserObj: state => state.userObj,
  getLoggedIn: state => state.loggedIn,
  getUserLogo: state => state.logo || localStorage.getItem("logo")
};

// actions
const actions = {
  async login({ commit }, userInfo) {
    try {
      const resp = await login(userInfo.username, userInfo.password);
      const token = resp.token;
      const refreshToken = resp.refreshToken;
      const userID = resp.id;
      localStorage.setItem("userID", userID);
      localStorage.setItem("username", userInfo.username);
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const logo = await getUserLogo();
      commit("SET_USER_LOGO", logo);
      commit("SET_ADMIN_FLAG", isAdminToken(token));
      refreshBeforeSend();
      commit("LOGIN", {});
    } catch (e) {
      localStorage.removeItem("token");
      throw e;
    }
  },

  async logout({ commit }) {
    try {
      const refresh_token = localStorage.getItem("refresh_token");
      await logout(refresh_token);
      localStorage.clear();
      axios.defaults.headers.common["Authorization"] = "";
      commit("LOGOUT");
    } catch (e) {
      throw e;
    }
  },

  refreshToken({ commit }) {
    commit("SET_REFRESHING", true);
    let userID = localStorage.getItem("userID");
    let refresh_token = localStorage.getItem("refresh_token");

    return new Promise((resolve, reject) => {
      return refreshToken(userID, refresh_token)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("SET_REFRESHING", false);
        });
    });
  },

  async fetchUserInfo({ commit }) {
    try {
      const userID = null;
      // Admin mode
      if (userID != null && userID != undefined) {
        commit("SET_USER_DETAILS", null);
        const resp = await getUserInfo(userID);
        commit("SET_USER_DETAILS", resp);
      } else {
        // User mode
        const resp = await getUserInfo();
        commit("SET_USER_DETAILS", resp);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

// mutations
const mutations = {
  LOGIN: (state, userObj) => ((state.userObj = userObj), (state.loggedIn = true)),
  LOGOUT: state => (state.userObj = null),
  SET_ADMIN_FLAG: (state, value) => (state.isAdmin = value),
  SET_REFRESHING: (state, value) => (state.refreshingToken = value),
  SET_USER_DETAILS: (state, userObj) => (state.userObj = userObj),
  SET_USER_LOGO: (state, logo) => ((state.logo = logo), localStorage.setItem("logo", logo))
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
